import * as React from 'react';
import { useEffect } from 'react';
// import '../sass/main.scss';
import '../sbAssets/css/styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import NavBar from '../components/navbar';
import IndexHeader from '../components/indexHeader';
import SvgBorderRounded from '../components/svgBorderRounded';
import Footer from '../components/footer';
import FeatherIcon from 'feather-icons-react';
// ..

// markup
const LayoutDefault = ({ children, navIsColored }) => {
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration: 600,
      once: true,
    });
  });
  return (
    <>
      <div id="layoutDefault">
        <div id="layoutDefault_content">
          <main>
            <NavBar navIsColored={navIsColored} />

            {children}
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LayoutDefault;
