import * as React from 'react';

import FeatherIcon from 'feather-icons-react';
import logo from '../images/Metanoia_greek_rect.png';

const NavBar = ({ navIsColored }) => {
  const coloredNavBg = 'bg-gradient-primary-to-secondary navbar-dark';
  const coloredBrandBg = 'text-white';

  const whiteNavBg = 'bg-white navbar-light';
  const whiteBrandBg = 'text-primary';

  console.log(navIsColored);

  return (
    <nav
      className={`navbar navbar-marketing navbar-expand-lg ${
        navIsColored ? coloredNavBg : whiteNavBg
      }`}
    >
      <div className="container px-5">
        <a
          className={`navbar-brand ${
            navIsColored ? coloredBrandBg : whiteBrandBg
          }`}
          href="/"
        >
          {/* Meta Noia Web Labs */}
          <img
            src={logo}
            alt="Logo"
            style={{ width: '200px', height: 'auto' }}
          />
        </a>
        <button
          className="navbar-toggler text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FeatherIcon className="" icon="menu" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto me-lg-5 text-white">
            <li className="nav-item dropdown dropdown-xl no-caret">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdownDemos"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
                <FeatherIcon className="dropdown-arrow" icon="chevron-right" />
              </a>
              <div
                className="dropdown-menu dropdown-menu-end animated--fade-in-up me-lg-n25 me-xl-n15"
                aria-labelledby="navbarDropdownDemos"
              >
                <div className="row g-0">
                  <div className=" p-lg-5">
                    <div className="dropdown-divider border-0" />
                    <div className="">
                      <div className="col-2">
                        <h6 className="dropdown-header text-primary">
                          Custom Web App Development
                        </h6>
                        <a
                          className="dropdown-item"
                          href="landing-multipurpose.html"
                        >
                          Product Engineering
                        </a>
                        <a className="dropdown-item" href="landing-agency.html">
                          Digital Transformation
                        </a>

                        <div className="dropdown-divider border-0" />
                        <h6 className="dropdown-header text-primary">
                          Systems Thinking
                        </h6>
                        <a
                          className="dropdown-item"
                          href="landing-lead-capture.html"
                        >
                          Process Analysis
                        </a>
                        <a
                          className="dropdown-item"
                          href="landing-lead-capture.html"
                        >
                          Digital Transformation Strategies
                        </a>
                        <div className="dropdown-divider border-0 d-lg-none" />
                        <h6 className="dropdown-header text-primary">
                          AI Development
                        </h6>
                        <a
                          className="dropdown-item"
                          href="landing-multipurpose.html"
                        >
                          Custom AI Solutions
                        </a>
                        <div className="dropdown-divider border-0 d-lg-none" />
                        <h6 className="dropdown-header text-primary">
                          Custom Electronics Development
                        </h6>
                        {/* <a
                          className="dropdown-item"
                          href="landing-multipurpose.html"
                        >
                          Custom Electrocis Solutions
                        </a> */}
                        <div className="dropdown-divider border-0 d-lg-none" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/our-work">
                Work
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="/case-studies">
                Case Studies
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/blog">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/pricing">
                Pricing
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/contact">
                Contact
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="/careersOverview">
                Careers List
              </a>
            </li> */}
          </ul>
          <a className="btn fw-500 ms-lg-4 btn-primary" href="/contact">
            Contact Us
            <FeatherIcon className="ms-2" icon="arrow-right" />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
