import * as React from 'react';

import creativity from '../sbAssets/assets/img/illustrations/creativity.svg';
import AnimatedSvgImage from './animatedSvgImage';
// import logo from '../images/Metanoia_greek_rect.png';
// <img src={logo} alt="Logo" />

import FeatherIcon from 'feather-icons-react';

const IndexHeader = () => {
  return (
    <header className="page-header-ui page-header-light text-white py-lg-5 bg-dark">
      <div className="page-header-ui-content">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-5">
              <h1 className="page-header-ui-title text-white">
                We build <br /> Custom Web Tools.
              </h1>
              <p className="page-header-ui-text mb-5">
                Digital tools for{' '}
                <abbr title="Small and medium-sized enterprises">SME's</abbr>,
                crafted with systems thinking, delivered reliably and securely
                through the web.
              </p>
              <a className="btn btn-primary fw-500 me-2" href="#!">
                Contact Us <FeatherIcon className="ms-1" icon="arrow-right" />
              </a>
              <a className="btn btn-primary-soft text-primary fw-500" href="#!">
                Learn More
              </a>
            </div>
            <div className="col-lg-7 d-none d-lg-block">
              <AnimatedSvgImage style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>
      <div className="svg-border-rounded bg-white" style={{ color: '#2c2e79' }}>
        {/* Rounded SVG Border*/}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144.54 17.34"
          preserveAspectRatio="none"
          fill="currentColor"
        >
          <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
        </svg>
      </div>
    </header>
  );
};

export default IndexHeader;
